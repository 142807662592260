body {
  background-color: #e0e7ea;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
}

/* .dashboard {
  width: 100%;
  height: 1144px;
  background-repeat: no-repeat;
  background-size: 100% 1144px;
} */

.btn-primary{
  background-color:#3399ff;
}

.nav-link {
  cursor: pointer;

  transition: all 0.2s ease-in-out;
}

.nav-link:hover {
  background-color: #138496ad;
  color: white !important;

  margin: auto 1px;

  border-radius: 5px;
}

.nav-link.active {
  background-color: #138496;
  color: white !important;

  margin: auto 1px;

  border-radius: 5px;
}

.form-email, .form-password{
  background-color:#F6F6F6;
  border: 2px solid #F6F6F6;
  height: 60px;
}

.form-email:focus, .form-password:focus {
  border-color:#F6F6F6;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #fff;
  outline: none!important;
  border-bottom: 2px solid #5fbae9;
  
}

.page-link{
  cursor: pointer;
}

.windcompass {
  position: absolute;
  top: 76px;
  left: 20px;

}

.logout-timeout{
  position: absolute;
  width: 100vw;
  top: 56px;
  z-index: 2;
}

.btn-times-close{
  font-size: 1.5rem;
  color: #dc3545;
  cursor: pointer;

  transition: all 0.3s ease-in-out;
}

.btn:disabled{
  cursor: not-allowed;
}

.btn-times-close:hover{
  color:  #c52231;
}

.datetimeselector {
  background-color: #F6F6F6;
  
  width: 800px;
  position: absolute;
  bottom: 54px;
  left: 0;

  z-index: 2;

  border-top-right-radius: 15px ; 

  overflow: hidden;
}

.mini-chart svg{
  cursor: pointer;
}

.mini-chart-active svg{
  cursor: pointer;
}

.page-link {
  color: rgb(85, 84, 84);
  
}
.page-item.disabled .page-link {
  color: #868e96;
  pointer-events: none;
  cursor: auto;
}
.page-item.active .page-link {
  color: #fff;
  background-color: #17A2B8;

}
.page-link:focus, .page-link:hover {
  color: #fff;
  background-color: #17A2B8;
}


/*
* Canvas
*/
.map-dust{
  display: block;
}

/*
* Admin users
*/
.delete-icon {
  cursor: pointer;
}

.delete-icon:hover { 
  color: #e74c3c;
}

.change-icon {
  cursor: pointer;
}

.change-icon:hover { 
  color: #f1c40f;
}

.edit-icon {
 cursor: pointer;
}

.edit-icon:hover {
  color: #f1c40f;
}

.btn-glowing {
  color: white !important;
  -webkit-animation: glowing 1500ms infinite;
  -moz-animation: glowing 1500ms infinite;
  -o-animation: glowing 1500ms infinite;
  animation: glowing 1500ms infinite;
}

.box-glowing {
  -webkit-animation: glowing 1500ms infinite;
  -moz-animation: glowing 1500ms infinite;
  -o-animation: glowing 1500ms infinite;
  animation: glowing 1500ms infinite;
}

/* Button glowing animation */
@-webkit-keyframes glowing {
  0% { background-color: #B20000; -webkit-box-shadow: 0 0 3px #B20000; }
  50% { background-color: #FF0000; -webkit-box-shadow: 0 0 40px #FF0000; }
  100% { background-color: #B20000; -webkit-box-shadow: 0 0 3px #B20000; }
}

@-moz-keyframes glowing {
  0% { background-color: #B20000; -moz-box-shadow: 0 0 3px #B20000; }
  50% { background-color: #FF0000; -moz-box-shadow: 0 0 40px #FF0000; }
  100% { background-color: #B20000; -moz-box-shadow: 0 0 3px #B20000; }
}

@-o-keyframes glowing {
  0% { background-color: #B20000; box-shadow: 0 0 3px #B20000; }
  50% { background-color: #FF0000; box-shadow: 0 0 40px #FF0000; }
  100% { background-color: #B20000; box-shadow: 0 0 3px #B20000; }
}

@keyframes glowing {
  0% { background-color: #B20000; box-shadow: 0 0 3px #B20000; }
  50% { background-color: #FF0000; box-shadow: 0 0 40px #FF0000; }
  100% { background-color: #B20000; box-shadow: 0 0 3px #B20000; }
}